<template>
  <v-card
    :min-width="width"
    :min-height="height"
    :max-height="height + 20"
    :max-width="width + 20"
    :color="color"
  >
    <v-card-text class="text-center">
      <span class="text" :style="{ color: textColor(color) }">
        {{ label }}
        <br />
        {{ color }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import chroma from 'chroma-js'
export default {
  props: {
    label: {
      type: String
    },
    color: {
      type: String
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      text: { light: 'white', dark: 'black' }
    }
  },
  methods: {
    textColor(bg) {
      return chroma(bg).get('lab.l') < 80 ? this.text.light : this.text.dark
    }
  }
}
</script>

<style></style>
